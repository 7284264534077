var monthlyPrice = ["£99", "£199", "£299", "£499"];
var monthlyPriceDesc = "per month - no contract";
var monthlyPlanTermDesc = "Monthly billing. No contract. Cancel anytime.";
var annualPrice = ["£??", "£??", "£??", "£??"];
var annualPriceDesc = `per month <br> 12 months contract`;
var annualPlanTermDesc = "Monthly billing. 12 months contract. 3 months free! Best value!"

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

$(".mini-row button").on("click", function () {
    $(".mini-row button").removeClass("active");
    $(this).addClass("active");
    planChange();
});

function planChange() {
    var priceTitle = $(".price-title");
    var priceDesc = $(".price-desc");
    var planTermDesc = $(".plan-term-desc");

    if ($("#pricePlanMonthlyBtn").hasClass("active")) {
        $.each(priceTitle, function (i, el) {
            fadeItIn(el, monthlyPrice[i]);
        });
        fadeItIn(priceDesc, monthlyPriceDesc);
        fadeItIn(planTermDesc, monthlyPlanTermDesc);
    } else {
        $.each(priceTitle, function (i, el) {
            fadeItIn(el, annualPrice[i]);
        });
        fadeItIn(priceDesc, annualPriceDesc);
        fadeItIn(planTermDesc, annualPlanTermDesc);
    }
};

function fadeItIn(what, txt) {
    $(what).fadeOut(function () {
        $(this).html(txt).fadeIn();
    });
};

$(document).ready(function () {
    $('a[href*="#"]:not([href="#"])').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') ||
            location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top - 100
                }, 1000);
                return false;
            };
            target.focus(); // Setting focus
            if (target.is(":focus")) { // Checking if the target was focused
                return false;
            } else {
                target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                target.focus(); // Setting focus
            };
        }

    });
});

$(".contact-row .mandatory[type='text']").on("change", function () {
    var that = this.classList;
    if (this.value) {
        that.remove("mandatory", "red-shadow");
    } else {
        that.add("mandatory", "animate-shake", "red-shadow");
        setTimeout(function () {
            that.remove("animate-shake");
        }, 600);
    }
});

$(".contact-row .mandatory[type='email']").on("change", function () {
    if (validateEmail(this.value)) {
        this.classList.remove("mandatory", "animate-shake", "red-shadow");
    } else {
        var that = this;
        this.classList.add("mandatory", "animate-shake", "red-shadow");
        setTimeout(function () {
            that.classList.remove("animate-shake")
        }, 600);
    }
});

document.querySelector("#newsletterCheck").addEventListener("change", function () {
    var newsletterLabel = document.querySelector("label[for='newsletterCheck']");
    newsletterLabel.innerText = this.checked ?
        "We will send you our further information from MezurIt relating to our products, services, news and special offers by email." :
        "Would you like to opt in to receive further information from MezurIt relating to our products, services, news and special offers by email?"
});

function validateEmail(email) {
    var reg = /\S+@\S+\.\S+/;
    return reg.test(email);
}

function formSend(token) {
    $('#loadingSpinner').show();
    grecaptcha.reset();

    var name = $('#name').val();
    var email = $('#email').val();
    var phone = $('#phone').val();
    var company = $('#company').val();
    var message = $('#message').val();
    var marketing = $('#newsletterCheck').prop("checked");

    if (!validateEmail(email) || name === '' || name === null) {

    } else {
        $.ajax({
            url: '/querysave',
            data: {
                name: name,
                email: email,
                phone: phone,
                company: company,
                message: message,
                marketing: marketing
            },
            type: 'POST',
            async: true
        }).done(function (data) {
            $('#loadingSpinner').hide();
            $("#confirmModalContent").text("Thank you for your message. We will get back to you shortly.");
            $("#confirmModal").modal("show");
            setTimeout(function () {
                $('#confirmModal').modal('hide');
            }, 3000);
        }).fail(function (data) {
            $('#loadingSpinner').hide();
            $("#confirmModalContent").text("An error occurred. Please try again later.");
            $('#confirmModal').modal('show');
            setTimeout(function () {
                $('#confirmModal').modal('hide');
            }, 3000);
        });
    }
};

// Creare's 'Implied Consent' EU Cookie Law Banner v:2.4
// Conceived by Robert Kent, James Bavington & Tom Foyster

var dropCookie = true; // false disables the Cookie, allowing you to style the banner
var cookieDuration = 9999; // Number of days before the cookie expires, and the banner reappears
var cookieName = 'complianceCookie'; // Name of our cookie
var cookieValue = 'on'; // Value of cookie 

function createDiv() {
    var bodytag = document.getElementsByTagName('body')[0];
    var div = document.createElement('div');
    div.setAttribute('id', 'cookie-law');
    div.innerHTML = `
    <p class="cookie-text">Our website uses cookies to ensure you get the best experience on our website.
    </p>
    <p>
        <a class="close-cookie-banner" href="javascript:void(0);" onclick="removeMe();">
        <span>Got it!</span>
    </a>
    </p>
    `;

    // as detailed in our 
    // <a href="/privacy-cookies-policy/" rel="nofollow" title="Privacy &amp; Cookies Policy">privacy and cookies policy</a>

    // Be advised the Close Banner 'X' link requires jQuery

    bodytag.appendChild(div); // Adds the Cookie Law Banner just before the closing </body> tag
    // or
    // bodytag.insertBefore(div, bodytag.firstChild); // Adds the Cookie Law Banner just after the opening <body> tag

    document.getElementsByTagName('body')[0].className += ' cookiebanner'; //Adds a class tothe <body> tag when the banner is visible

};

function createCookie(name, value, days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        var expires = "; expires=" + date.toGMTString();
    } else var expires = "";
    if (window.dropCookie) {
        document.cookie = name + "=" + value + expires + "; path=/";
    }
};

function checkCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
};

function eraseCookie(name) {
    createCookie(name, "", -1);
};

$(document).ready(function () {
    if (checkCookie(window.cookieName) != window.cookieValue) {
        createDiv();
    }
})

function removeMe() {
    var element = document.getElementById('cookie-law');
    element.parentNode.removeChild(element);
    createCookie(window.cookieName, window.cookieValue, window.cookieDuration); // Create the cookie
};

function TxtType(el, toRotate, period) {
    this.toRotate = toRotate;
    this.el = el;
    this.loopNum = 0;
    this.period = parseInt(period, 10) || 2000;
    this.txt = '';
    this.tick();
    this.isDeleting = false;
};

TxtType.prototype.tick = function () {
    var i = this.loopNum % this.toRotate.length;
    var fullTxt = this.toRotate[i];

    if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
    }

    this.el.innerHTML = '<span class="wrap">' + this.txt + '</span>';

    var that = this;
    var delta = 200 - Math.random() * 100;

    if (this.isDeleting) {
        delta /= 2;
    }

    if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
    } else if (this.isDeleting && this.txt === '') {
        this.isDeleting = false;
        this.loopNum++;
        delta = 500;
    }

    setTimeout(function () {
        that.tick();
    }, delta);
};

window.onload = function () {
    var elements = document.getElementsByClassName('typewrite');

    for (var i = 0; i < elements.length; i++) {
        var toRotate = elements[i].getAttribute('data-type');
        var period = elements[i].getAttribute('data-period');
        if (toRotate) {
            new TxtType(elements[i], JSON.parse(toRotate), period);
        }
    }
};

function animateValue(id, start, end, duration) {
    var obj = id;
    start = start || 1;
    var range = (end = end || Number(obj.textContent)) - start,
        minTimer = 50,
        stepTime = Math.abs(Math.floor(duration / range));
    stepTime = Math.max(stepTime, 50);
    var startTime, endTime = (new Date).getTime() + duration,
        timer;

    function run() {
        var now = (new Date).getTime(),
            remaining = Math.max((endTime - now) / duration, 0),
            value = Math.round(end - remaining * range);
        obj.innerHTML = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","), value == end && clearInterval(timer)
    }
    timer = setInterval(run, stepTime), run()
};
var counterHours = document.getElementById("counterHours");

function calcHoursMeasured(devices) {
    const dStart = new Date(2003, 0, 1);
    const dEnd = new Date();
    const calcHours = Math.round((dEnd - dStart) / 1000); // amount of seconds passed
    return Math.abs(Math.round(calcHours / 3600)) * devices;
};

const hoursMeasured = calcHoursMeasured(41);

counterHours.innerText = hoursMeasured;

var calcValue = document.getElementsByClassName("counter-number");
for (let i = 0; i < calcValue.length; i++) {
    var x = 1e3,
        len = calcValue[i].textContent.trim().length;
    animateValue(calcValue[i], 1, calcValue[i].textContent.trim(), x * [len])
};

const observer = window.lozad(); // lazy loads elements with default selector as '.lozad'
observer.observe();
